import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/seva/shramdhan/1.jpg';
// import p2 from 'assests/Photos/seva/shramdhan/2.jpg';
// import p3 from 'assests/Photos/seva/shramdhan/3.jpg';
// import p4 from 'assests/Photos/seva/shramdhan/4.jpg';
// import p5 from 'assests/Photos/seva/shramdhan/5.jpg';
// import p6 from 'assests/Photos/seva/shramdhan/6.jpg';
// import p7 from 'assests/Photos/seva/shramdhan/7.jpg';
// import p8 from 'assests/Photos/seva/shramdhan/8.jpg';
// import p9 from 'assests/Photos/seva/shramdhan/9.jpg';
// import p10 from 'assests/Photos/seva/shramdhan/10.jpg';
// import p11 from 'assests/Photos/seva/shramdhan/11.jpg';




import { Typography } from '@mui/material';
import Container from 'components/Container';
import SideBar from 'views/SEWA/SideBar';
import { Sidebar2023 } from 'views/ScintillaOfArt2023/components';

const Shramdhan2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/7.webp`;
  const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/8.webp`;
  const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/9.webp`;
  const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/10.webp`;
  const p11 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/shramdhan/11.webp`;
 

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
        },
        {
          src: p6,
          source: p6,
          rows: 2,
          cols: 1,
        },
        {
          src: p7,
          source:p7,
          rows: 1,
          cols: 1,
        },
        {
          src: p8,
          source: p8,
          rows: 1,
          cols: 1,
        },
        {
          src: p9,
          source: p9,
          rows: 1,
          cols: 1,
        },
        {
              src: p10,
              source: p10,
              rows: 1,
              cols: 1,
            },
            {
              src: p11,
              source: p11,
              rows: 1,
              cols: 1,
            },
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      SHRAMDHAN 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 12	  &  Date: 13 OCTOBER 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of grade 12 of National Public School Yeshwanthpur came together in a heartwarming display of gratitude and appreciation on Friday October 13 as they organised an event to serve food to the dedicated ancillary and logistics staff who work tirelessly behind the scenes to keep our school clean and safe. This act of kindness was a wonderful opportunity for students to express their thankfulness and recognize the often-overlooked contributions of these essential staff members as a part of SEWA (Social Empowerment through Work Education and Action).
        <br></br>
        The event was filled with smiles, enthusiasm and a sense of camaraderie as students served the food to the essential staff members, picked up their plates and cleaned the area. While the essential Staff expressed their appreciation of the unique act and conveyed their best wishes to the students; The Principal Ms Sheeja Manoj, the brain behind this unique event, stressed the importance of values and blessings as an essential element of everybody’s life.  The student-Led Gratitude Initiative was a heartening example of the positive impact of acknowledging the hard work and dedication of essential staff members in a school community. It fostered a sense of unity and demonstrated the power of gratitude in building a supportive and compassionate environment. This event served as a reminder that small acts of kindness can have a lasting and meaningful impact on the relationships and overall well-being of everyone in our school community. 
        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“Climate change is the Everest of all problems, the thorniest challenge facing humankind - Lewis Gordon Pugh”

<br/>
        </Typography> */}

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023/>
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default Shramdhan2023;



